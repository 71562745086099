import React from 'react';
import Masonry from 'react-masonry-component';
import styled from 'styled-components';
import { minBreakpointQuery } from '../styles';
import { Container } from './ui';
import Pagination from './Pagination';
import BlogCard from './BlogCard';

const StyledBlogCards = styled.section``;

const StyledContainer = styled(Container)`
  position: relative;
`;

const StyledMasonry = styled(Masonry)`
  margin-top: 30px;

  ${minBreakpointQuery.mlarge`
    margin-top: 40px;
  `}
`;

const StyledBlogCard = styled(BlogCard)`
  margin-bottom: 20px;
  ${minBreakpointQuery.small`
    width: calc(50% - 10px);
  `}

  ${minBreakpointQuery.mlarge`
    width: calc(33% - 10px);
  `}
`;

const StyledPagination = styled(Pagination)`
  margin-top: 40px;
  margin-left: auto;
`;

const BlogCards = ({ items, meta }) => (
  <StyledBlogCards>
    <StyledContainer>
      {items.length > 0 && (
        <StyledMasonry
          options={{
            itemSelector: '.masonry-item',
            percentPosition: true,
            gutter: 20,
          }}
        >
          {items.map(item => {
            return (
              <StyledBlogCard
                key={item.id}
                className="masonry-item"
                title={item.title}
                slug={item.slug}
                featuredImage={item.featuredImage}
                createdAt={item.createdAt}
                metaCreatedDate={item.metaCreatedDate}
                introText={item.introText}
                category={item.category}
              />
            );
          })}
        </StyledMasonry>
      )}
      <StyledPagination {...meta} />
    </StyledContainer>
  </StyledBlogCards>
);
export default BlogCards;
