import React from 'react';
import styled from 'styled-components';
import {
  minBreakpointQuery,
  fontSize,
  brandColours,
  fontWeights,
} from '../styles';
import { Link } from './ui';

const StyledPagination = styled.nav``;

const StyledItems = styled.ol`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 10px;
`;

const StyledItem = styled.li``;

const StyledLink = styled(Link)`
  padding: 10px 15px;
  width: 40px;
  height: 40px;
  background-color: ${brandColours.octonary};
  font-weight: ${fontWeights.semibold};
  transition: 0.5s;

  ${minBreakpointQuery.medium`
    padding-top: 7px;
    padding-bottom: 7px;
    ${fontSize(18)};
  `}

  ${minBreakpointQuery.large`
    ${fontSize(21)};
  `}

  &:disabled {
    opacity: 0.5;
    cursor: inherit;
  }

  &:not([disabled]):hover,
  &.current-page {
    background-color: ${brandColours.tertiary};
  }
`;

const Pagination = ({ baseUrl, totalPages, ...props }) =>
  totalPages > 1 && (
    <StyledPagination {...props}>
      <StyledItems>
        {[...Array(totalPages)].map((item, i) => {
          const pageCount = i + 1;
          return (
            <StyledItem key={i}>
              <StyledLink
                to={`${baseUrl}${pageCount > 1 ? `page/${pageCount}` : ''}`}
                activeClassName="current-page"
              >
                {pageCount}
              </StyledLink>
            </StyledItem>
          );
        })}
      </StyledItems>
    </StyledPagination>
  );

export default Pagination;
