import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import Banner from '../components/Banner';
import BlogCards from '../components/BlogCards';

const BlogArchiveTemplate = ({
  data: {
    contentfulSiteData: { siteName },
    contentfulBlogArchive: { title, heading, metaDescription },
    allContentfulBlog: { nodes },
  },
  pageContext,
}) => (
  <Layout title={title} description={metaDescription} siteName={siteName}>
    <main>
      <Banner heading={heading || title} isExtended={true} />
      <BlogCards items={nodes} meta={pageContext} />
    </main>
  </Layout>
);

export const BlogArchiveTemplateQuery = graphql`
  query BlogArchiveTemplateQuery($limit: Int!, $skip: Int!) {
    contentfulSiteData {
      siteName
    }
    contentfulBlogArchive {
      title
      heading {
        raw
      }
      metaDescription
    }
    allContentfulBlog(
      sort: { fields: createdAt, order: DESC }
      limit: $limit
      skip: $skip
    ) {
      nodes {
        id
        title
        slug
        featuredImage {
          title
          gatsbyImageData(width: 620, height: 640)
        }
        createdAt(formatString: "DD MMMM YYYY")
        metaCreatedDate: createdAt(formatString: "YYYY-MM-DD")
        introText {
          introText
        }
        category {
          title
        }
      }
    }
  }
`;

export default BlogArchiveTemplate;
